<template>
<section class="hero-wrap clearfix" style="background-size: cover; background-repeat: no-repeat; background-image: url(/images/flabra2.jpg);" data-stellar-background-ratio="0.5">
    <div class="overlay"></div>
    <div class="container container-top">
        <div class="text">
            <h1>PODCASTS</h1>
            <div v-if="getAccessLevel() > 0">
              <div class="card mb-3 bg-dark" style="max-width: 540px; padding: 4px; font-size: 13pt;">
                <router-link :to="{name: 'podcast-subscribe', params: { id: broadcastChannelId }}">
                    <img src="/images/rss.png" height="32"/> Subscribe to Radio FlaBra's podcast feed [<strong>rss.xml</strong>]
                </router-link>
              </div>
            </div>
            <div :key="broadcast.id" v-for="broadcast in broadcasts">
                <BroadcastsItem :broadcast="broadcast" />
            </div>

        </div>
    </div>
</section>

</template>

<script>
import BroadcastsItem from '../components/BroadcastsItem'
import {fbLogin, fbGetAccessLevel, fbGetBroadcasts} from '../assets/apiclient.js'

export default {
  name: 'Broadcasts',
  components: {
    BroadcastsItem,
  },
  data() {
      return {
        broadcasts: [],
        broadcastChannelId: null,
      }
  },
  methods: {
    updateChannelId() {
      this.broadcastChannelId = this.broadcasts[0].channel;
    },
    getAccessLevel() {
      return fbGetAccessLevel();
    }
  },
  async created() {
      this.broadcasts = JSON.parse(sessionStorage.getItem('fb-broadcasts'));
      if (this.broadcasts != null) {
        this.updateChannelId();
        return;
      }

      this.broadcasts = await fbGetBroadcasts();
      sessionStorage.setItem('fb-broadcasts', JSON.stringify(this.broadcasts));
      this.updateChannelId();
  }
}
</script>

<style>
</style>
