<template>
    <div class="card mb-3 bg-dark" style="max-width: 540px;">
        <div class="row no-gutters">
            <div class="col-md-4">
                <router-link :to="{name: 'release', params: { id: album.id }}">
                    <AuthImage :src="[album.images.filter((item) => item.purpose === 'thumbnail')[0].href]" class="card-img" style="max-width: 190px;" alt="..." />
                </router-link>

            </div>
            <div class="col-md-8">
                <div class="card-body">
                    <h5 class="card-title"><router-link :to="{name: 'release', params: { id: album.id }}" class="card-title" style="color: white;">{{album.name}}</router-link></h5>
                    <p class="card-text"><router-link :to="{name: 'author', params: { id: album.by.id }}">{{album.by.name}}</router-link></p>
                    <p class="card-text"><small class="text-muted">{{album.releaseId}} (MP3-{{getDuration(album.duration)}})<br/>&copy; {{getDate(album.releaseInfo.releaseDate)}} Flabra Music</small></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuthImage from './AuthImage.vue'

export default {
  components: { AuthImage },
    name: 'AlbumReleasesItem',
    props: {
        album: Object,
    },
    methods: {
        getDate(date) {
            var d = new Date(date);
            return (d.getUTCMonth() + 1) + "/" + d.getUTCFullYear();
        },
        getDuration(secs) {
            return Math.ceil(secs / 60);
        }
    }
}
</script>