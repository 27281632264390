import {createRouter, createWebHistory} from 'vue-router'
import Publist from '../views/Publist'
import PublistDetail from '../views/PublistDetail'
import AuthorDetail from '../views/AuthorDetail'
import Changelog from '../views/Changelog'
import Profile from '../views/Profile'
import Profile2faEnable from '../views/Profile2faEnable'
import Video from '../views/Video'
import Broadcasts from '../views/Broadcasts'
import Broadcast from '../views/Broadcast'
import BroadcastsSubscribe from '../views/BroadcastsSubscribe'
import Home from '../views/Home'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/index.html',
        redirect: { name: 'Home' }
    },
    {
        path: '/releases',
        name: 'releases',
        component: Publist
    },
    {
        path: '/releases/:id',
        name: 'release',
        component: PublistDetail
    },
    {
        path: '/podcasts',
        name: 'podcasts',
        component: Broadcasts
    },
    {
        path: '/podcasts/:id',
        name: 'podcast',
        component: Broadcast
    },
    {
        path: '/podcasts/subscribe/:id',
        name: 'podcast-subscribe',
        component: BroadcastsSubscribe
    },
    {
        path: '/videos/:id',
        name: 'videos',
        component: Video
    },
    {
        path: '/author/:id',
        name: 'author',
        component: AuthorDetail
    },
    {
        path: '/changelog',
        name: 'changelog',
        component: Changelog
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile
    },
    {
        path: '/profile/2fa/enable',
        name: 'profile2faEnable',
        component: Profile2faEnable
    },
]

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition)
            return savedPosition;
        return { top: 0 };
    },
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
