<template>
<section class="hero-wrap clearfix" style="background-size: cover; background-repeat: no-repeat; background-image: url(images/flabra2.jpg);" data-stellar-background-ratio="0.5">
    <div class="overlay"></div>
    <div class="container container-top">
        <div class="text">
            <h1>RELEASES</h1>

            <div :key="album.id" v-for="album in albums">
                <AlbumReleasesItem :album="album" />
            </div>

        </div>
    </div>
</section>

</template>

<script>
import AlbumReleasesItem from '../components/AlbumReleasesItem'
import {fbLogin, fbLogout, fbGetAlbums} from '../assets/apiclient.js'

export default {
  name: 'App',
  components: {
    AlbumReleasesItem,
  },
  data() {
      return {
        albums: [],
      }
  },
  async created() {
      this.albums = JSON.parse(sessionStorage.getItem('fb-releases'));
      if (this.albums != null)
        return;

      this.albums = await fbGetAlbums();
      sessionStorage.setItem('fb-releases', JSON.stringify(this.albums));
  }
}
</script>

<style>
</style>
