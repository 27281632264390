<template>
<section class="hero-wrap clearfix" :style="{backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: 'url(' + fetchBackground() + ')'}" data-stellar-background-ratio="0.5">
    <div class="overlay"></div>
    <div class="container container-top">
        <div v-if="album !== null" class="row">
            <div class="col-sm">
                <div class="card mb-3 bg-dark" style="max-width: 600px; border-radius: 12px;">
                    <div class="row no-gutters">
                        <div class="col-md-4">
                            <a v-if="album.images.filter((item) => item.purpose === 'front').length != 0" data-toggle="modal" data-target="#modalDisable" href="#">
                                <AuthImage :src="album.images.filter((item) => item.purpose === 'thumbnail')[0].href" class="card-img" style="max-width: 190px; border-radius: 8px;" alt="..." />
                            </a>
                            <AuthImage v-if="album.images.filter((item) => item.purpose === 'front').length == 0" :src="album.images.filter((item) => item.purpose === 'thumbnail')[0].href" class="card-img" style="max-width: 190px; border-radius: 8px;" alt="..." />
                            <p class="card-text"><small class="text-muted">
                                {{album.releaseId}} (MP3-{{getDuration(album.duration)}})<br/>
                                &copy; {{getDate(album.releaseInfo.releaseDate)}} Flabra Music</small>
                            </p>
                            <p class="card-text">
                                <button class="song-item" @click="$emit('enqueue-album', album.id)">
                                    <img src="/images/white-play-icon-7.jpg" height="48" />
                                </button> Enqueue album
                            </p>
                            <p class="card-text" v-if="getSuitableFormat() != null">
                                <a href="#" @click="useSuitableDownloadUri()">
                                    <img src="/images/ark2.png" height="48" /> Download ZIP <small class="text-muted">({{renderHumanSize(getSuitableFormat().size)}})</small>
                                </a>
                            </p>
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title"><div class="card-title" style="color: white;">
                                    {{album.name}}
                                </div></h5>
                                <h6 class="card-title"><div class="card-title" style="color: white;">
                                    <router-link :to="{name: 'author', params: { id: album.by.id }}">{{album.by.name}}</router-link>
                                </div></h6>

                                <p v-if="hasLimitableFormats()" class="card-text" style="background-color: #402020; color: #ffffc0;"><small text="text-muted">
                                    <p><img src="/images/redflag.png" height="12"/>&nbsp;<strong>Limited track availability</strong><br/><br/>
                                    {{getLimitedFormatsDescription()["Hint"]}}</p>
                                    <div v-if="getLimitedFormatsDescription()['Elab'] != null">
                                        <p>{{getLimitedFormatsDescription()["Elab"]}}</p>
                                    </div>
                                </small></p>

                                <p class="card-text">
                                    <ul class="list-group">
                                        <div :key="song.id" v-for="song in album.songs">
                                            <PlainSong :song="song" :currentSong="currentSong" @play-song="$emit('play-song', song.id)"></PlainSong>
                                        </div>
                                    </ul>
                                </p>

                                <p class="card-text" v-if="(album.relatedSongs.length > 0)">
                                    <div class="card-title" style="color: white;">
                                        Songs related to this album
                                    </div>
                                    <ul class="list-group">
                                        <div :key="song.id" v-for="song in album.relatedSongs">
                                            <PlainSongWithoutContext :song="song" :currentSong="currentSong" @play-song="$emit('play-song', song.id)"></PlainSongWithoutContext>
                                        </div>
                                    </ul>
                                </p>

                                <p class="card-text" v-if="(album.relatedVideos.length > 0)">
                                    <div class="card-title" style="color: white;">
                                        Videos related to this album
                                    </div>
                                    <ul class="list-group">
                                        <div :key="video.id" v-for="video in album.relatedVideos">
                                            <PlainVideoWithoutContext :video="video"></PlainVideoWithoutContext>
                                        </div>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="hasRelevantInformation(currentSong) > 0" class="col-sm">
                <div class="card mb-3 bg-dark" style="border-radius: 12px;">
                    <div class="container">
                        <h3>Currently playing</h3>
                        <ul class="list-group">
                            <PlainSongWithoutContext :song="currentSong.songObject" :currentSong="currentSong" @play-song="$emit('play-song', currentSong.id)"></PlainSongWithoutContext>
                        </ul>
                    </div>
                    <div v-if="currentSong.songObject.relatedToSong.length > 0" class="container">
                        <h3>Immediate relations</h3>
                        <ul class="list-group" :key="related.id" v-for="(related, index) in currentSong.songRelated">
                            <PlainSongRelated :song="related" :relation="getRelation(index)" :currentSong="currentSong" @play-song="$emit('play-song', related.id)"></PlainSongRelated>
                        </ul>
                    </div>
                    <div v-if="currentSong.songObject.relatedToAlbum.length > 0" class="container">
                        <h3>Related albums</h3>
                        <ul class="list-group" :key="related.id" v-for="related in currentSong.relatedAlbums">
                            <AlbumReference :album="related"></AlbumReference>
                        </ul>
                    </div>
                    <div v-if="currentSong.songObject.inBroadcast.length > 0" class="container">
                        <h3>Broadcast appearances</h3>
                        <ul class="list-group" :key="broadcast.id" v-for="broadcast in currentSong.songInBroadcast">
                            <!-- <PlainSongRelated :song="related" :relation="getRelation(index)" :currentSong="currentSong" @play-song="$emit('play-song', related.id)"></PlainSongRelated> -->
                            <PlainBroadcast :song="broadcast" :currentSong="currentSong" @play-song="$emit('play-song', broadcast.id, 'broadcast')"></PlainBroadcast>
                        </ul>
                    </div>
                    <div v-if="metadata != null">
                        <SongMetaData :metadata="metadata" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div v-if="album !== null && album.images.filter((item) => item.purpose === 'front').length != 0" class="modal fade" id="modalDisable" tabindex="-1" role="dialog" aria-labelledby="modalDisableLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-fullscreen" role="document">
    <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      <div id="modalDisable-modal-body" class="modal-body">
        <AuthImage :src="album.images.filter((item) => item.purpose === 'front')[0].href" class="card-img" style="border-radius: 8px;" alt="..." />
      </div>
    </div>
  </div>
</div>

</template>

<script>
import axios from 'axios'
import AuthImage from './AuthImage'
import PlainSong from './PlainSong'
import PlainSongRelated from './PlainSongRelated'
import PlainSongWithoutContext from './PlainSongWithoutContext'
import PlainVideoWithoutContext from './PlainVideoWithoutContext'
import PlainBroadcast from './PlainBroadcast'
import AlbumReference from './AlbumReference.vue'
import SongMetaData from './SongMetaData'
import {playPauseAudioJs} from '../assets/audiojs-data'
import {fbGetAccessLevel, fbLogin, fbGetMetaData} from '../assets/apiclient'

export default {
    name: 'AlbumReleasesDetail',
    components: {
        AuthImage,
        PlainSong,
        PlainSongRelated,
        PlainSongWithoutContext,
        PlainVideoWithoutContext,
        PlainBroadcast,
        AlbumReference,
        SongMetaData,
    },
    props: {
        album: Object,
        currentSong: Object,
    },
    data() { 
        return {
            metadata: null,
            songId: null,
            componentKey: 0,
    }},
    methods: {
        fetchBackground() {
            if (this.album == null) {
                console.log("fetchBackground: data not ready yet");
                return;
            }
            var item = this.album.images.filter((image) => image.purpose == 'background');
            if (item.length == 0)
                return;
            return item[0].href;
        },
        togglePlayPause(songId) {
            playPauseAudioJs();
        },
        showPlayState(id, from) {
            var result = 'stopped';
            if (this.currentSong == null)
                return result == from;
            if (this.currentSong.id != id)
                return result == from;
            return this.currentSong.playState == from;
        },
        getSongDuration(secs) {
            var minutes = Math.floor(secs / 60);
            var seconds = secs % 60;
            if (seconds < 10)
                seconds = '0' + seconds;
            return minutes + ':' + seconds;
        },
        hasRelevantInformation(song) {
            if (song != null && song.type != 'song')
                return 0;
            if (song != null && song.songObject != null && 
                (song.songObject.relatedToSong.length > 0 || song.songObject.inBroadcast.length > 0 || song.songObject.relatedToAlbum.length > 0)) {
                return 1;
            }
            if (song != null && song.songObject != null)
                return 1;

            return 0;
        },
        getRelation(index) {
            return this.currentSong.songObject.relatedToSong[index];
        },
        getDate(date) {
            var d = new Date(date);
            return (d.getUTCMonth() + 1) + "/" + d.getUTCFullYear();
        },
        getDuration(secs) {
            return Math.ceil(secs / 60);
        },
        getFormatsAccessLevels() {
            var result = [];

            this.album.formats.forEach(element => {
                if (!result.includes(element.accessLevel))
                    result.push(element.accessLevel);
            });

            return result.sort();
        },
        getHighestFormatsAccessLevel() {
            const levels = this.getFormatsAccessLevels();
            var high = 0;
            levels.forEach(element => {
                if (element > high)
                    high = element;
            });

            return high;
        },
        hasLimitableFormats() {
            return this.getHighestFormatsAccessLevel() > 1;
        },
        getLimitedFormatsDescription() {
            const levels = this.getFormatsAccessLevels();
            if (levels.length == 0)
                return null;
            
            if (!this.hasLimitableFormats())
                return null;

            var hint = "This album contains songs that are not considered adequate for every audience. "
                + "Those songs will not be accessible for you without appropriate account permissions. "
                + "If that's the case, they will also not be included if "
                + "you download the whole album as a ZIP file.";
            
            const accessLevel = fbGetAccessLevel();
            if (accessLevel == 0)
                return {Hint: hint, Elab: null};
            
            var result = "";
            if (this.getHighestFormatsAccessLevel() > accessLevel)
                result = "Based on your current AccessLevel, you cannot listen to every song on this album. Likewise, if you download the whole album ZIP file, certain songs will be missing.";
            else
                result = "Based on your current AccessLevel, you can listen to every song on this album. Likewise, all songs will be included in the downloaded ZIP file.";

            return {Hint: hint, Elab: result};
        },
        getSuitableFormat() {
            if (this.album.formats.length == 0)
                return null;

            if (fbGetAccessLevel() == 0)
                return this.album.formats[0]; // choose "any", because we'll be redirected to IdP anyway

            var chosenAudioIndex = 0;
            var bestLevel = 0;
            var index = 0;
            this.album.formats.forEach(element => {
                // console.log("Looking at audio format", element);
                // console.log("AccessLevel(audio format/mine): " + element.accessLevel + "/" + fbGetAccessLevel());
                // console.log("Test: element.accessLevel <= fbGetAccessLevel", element.accessLevel <= fbGetAccessLevel());
                // console.log("Test: element.accessLevel > bestLevel", element.accessLevel > bestLevel);
                if (element.accessLevel <= fbGetAccessLevel() && element.accessLevel > bestLevel) {
                    bestLevel = element.accessLevel;
                    chosenAudioIndex = index;
                    // console.log("Choosing index " + chosenAudioIndex + ", new bestLevel is " + bestLevel);
                }
                index++;
                // console.log("Index is at " + index);
            });
            
            return this.album.formats[chosenAudioIndex];
        },
        async useSuitableDownloadUri() {
            var targetUrl = this.getSuitableFormat().href + '/download';

            try {
                var response = await axios({
                    method: 'get',
                    headers: {
                        Range: "bytes=0-3"
                    },
                    url: targetUrl
                });

                document.location.href = targetUrl;
            } catch (ex) {
                console.log("Error downloading binary " + targetUrl);
                return fbLogin();
            }
        },
        renderHumanSize(bytes) {
            if (bytes < 1024)
                return bytes + ' bytes';
            if (bytes < 1048576)
                return Math.ceil(bytes / 1024) + 'K';
            if (bytes < 1073741824)
                return Math.ceil(bytes / 1048576) + 'M';
            return Math.ceil(bytes / 1099511627776) + 'G';
        },
    },
    async updated() {
        if (this.songId != this.currentSong.id) {
            this.metadata = null;
            this.songId = this.currentSong.id;
            var metadata = await fbGetMetaData(this.currentSong.id);
            if (metadata?.composition != null)
                this.metadata = metadata;
            this.componentKey++;
        }
    }
}
</script>