<template>
    <div class="card mb-3 bg-dark" style="max-width: 540px;">
        <div class="row no-gutters">
            <div class="col-md-4">
                <router-link :to="{name: 'podcast', params: { id: broadcast.id }}">
                    <img src="/images/podcast-icon.svg" class="card-img" style="max-width: 190px;" alt="..." />
                </router-link>
            </div>
            <div class="col-md-8">
                <div class="card-body">
                    <h5 class="card-title"><router-link :to="{name: 'podcast', params: { id: broadcast.id }}" class="card-title" style="color: white;">{{broadcast.name}}</router-link></h5>
                    <p class="card-text"><router-link :to="{name: 'author', params: { id: broadcast.by.id }}">{{broadcast.by.name}}</router-link></p>
                    <p class="card-text"><small class="text-muted">&copy; {{getDate(broadcast.releaseInfo.releaseDate)}} Flabra Music</small></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BroadcastsItem',
    props: {
        broadcast: Object,
    },
    methods: {
        getDate(date) {
            var d = new Date(date);
            return (d.getUTCMonth() + 1) + "/" + d.getUTCFullYear();
        },
        getDuration(secs) {
            return Math.ceil(secs / 60);
        }
    }
}
</script>