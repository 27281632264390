<template>
<video autoplay loop muted poster="/images/flabra2.jpg" class="hero__video">
    <source src="/images/background-uncompressed.webm" type="video/webm">
</video>

<section class="hero-wrap clearfix" style="min-height: 100%!important; height: 100%!important;">
    <!-- <div class="overlay"></div> -->
    <div class="container">
        <div class="row description align-items-center justify-content-center">
            <div class="col-md-8 text-center">
                <div class="text">
                    <h2>Flabra</h2>
                    <h4 class="mb-5">We're back</h4>
                </div>
            </div>
        </div>
    </div>
</section>    
</template>